import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { Box } from "@chakra-ui/core"
import GallerySection from "../components/Home/GallerySection"

const Gallery = (): JSX.Element => {
  return (
    <Box>
      <SEO title="Gallery" />
      <Layout>
        <GallerySection />
      </Layout>
    </Box>
  )
}

export default Gallery
